<template>
    <div class="main">
        <section class="section-share">
            <div class="row share-box">
                <div class="col-50">
                    <img src="./../assets/share-info.png" alt="img-share" class="img-share" />
                    <a href="https://live.euronext.com/en" target="_blank" class="btn">{{ $t('shareInformation.btn')}}</a>
                </div>
                <div class="col-50 col-text">
                    <p v-html="$t('shareInformation.text1')"></p>
                    <p v-html="$t('shareInformation.text2')"></p>
                    <p v-html="$t('shareInformation.text3')"></p>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name : "ShareInformation"
}
</script>

<style lang="scss" scoped>

.share-box {
    width:1200px;
    max-width: 90%;
    align-items: center;
}

.section-share {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: calc(100vh - 100px);
}

.col-50 {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.col-text {
    border-left:3px solid $mainColor;
    align-items: flex-start;
    padding-top:20px;
}

p {
    font-weight: 400;
    font-size:20px;
    margin-left: 20px;
}

.img-share {
    margin-bottom:20px;
}

@media screen and (max-width:768px) { 
    .col-text { 
        padding-top:40px;
        border:none;
    }

    p {
        font-size:15px;
    }
}
</style>